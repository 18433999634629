import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Box } from "@mui/material";
import RouteBlock from "./RouteBlock";
import { extractPageToken } from "../../../helpers/utils/extractPageToken";

interface PropsI {
  isAdmin?: boolean;
}

const EditorPage: React.FC<PropsI> = ({ isAdmin = false }) => {
  const [iframeElement, setIframeElement] = useState<HTMLIFrameElement>();
  const { pageId } = useParams<{ pageId: string }>();
  const websiteId = extractPageToken(pageId)?.websiteId;

  return (
    <>
      <RouteBlock iframeElement={iframeElement} websiteId={websiteId} isAdmin={isAdmin} />
      <Box
        ref={(el) => setIframeElement(el as HTMLIFrameElement)}
        component='iframe'
        sx={{ width: "100%", height: "100vh", border: "none", position: "absolute" }}
        allow='clipboard-read; clipboard-write'
        src={`${process.env.REACT_APP_WEBSITE_EDITOR_URL}/builder/${pageId}`}
      />
    </>
  );
};

export default EditorPage;
