import React from "react";
import ReactDOM from "react-dom";
import { RouteComponentProps } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import "./assets/scss/main.scss";

const rootElement = document.getElementById("WebsiteBuilder-root");

if (rootElement) {
  const isProductionMode = process.env.REACT_APP_PRODUCTION_MODE;

  if (isProductionMode && isProductionMode !== "true") {
    const rootLoadingElement = document.getElementById("root_loading");
    if (rootLoadingElement) rootLoadingElement.remove();

    ReactDOM.render(<App />, rootElement);
    serviceWorker.unregister();
  }
}

declare global {
  interface Window {
    renderWebsiteBuilderModule: (containerId: string, history: RouteComponentProps["history"]) => void;
    unmountWebsiteBuilderModule: (containerId: string) => void;
  }
}

window.renderWebsiteBuilderModule = (containerId: string, history: RouteComponentProps["history"]) => {
  const containerElement = document.getElementById(containerId);
  if (!containerElement) throw Error("container element not found");

  ReactDOM.render(<App history={history} />, containerElement);
  serviceWorker.unregister();
};

window.unmountWebsiteBuilderModule = (containerId: string) => {
  const containerElement = document.getElementById(containerId);
  if (containerElement) {
    ReactDOM.unmountComponentAtNode(containerElement);
  }
};













